import React from "react"
import {Container, Row, Col} from 'reactstrap'
import styled from "styled-components"


const WindowCover = styled.div`
  
  height: 100vh;
  width: 100vw;

  backround-color: #ff0000;
  position:fixed;

`


const Cover = (props) => {

  return (

      <WindowCover>
        <Container>
          Cover
        </Container>
      </WindowCover>
  );
  
}

export default Cover;
