import React from "react"

import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby';
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaGithub } from 'react-icons/fa'
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome } from 'react-icons/fa';

import Cover from '../components/Cover'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';

import MEDIA from '../helpers/mediaTemplates';





let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

let Center = styled.div`
  text-align: center;
`

let Content = styled.div`
  color:#ffffff;
`

let Service = ({title, Icon = FaHome}) => (
  <Col>
    <Link to="/">
      <Box>
        <Icon size={30}/>
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

let ShowListing = styled.div`
  display:block;
  .information{
    ${MEDIA.PHONE`
    background-color:white;
    padding: 10px 0;
    `
  }
  .btn{ 
    ${MEDIA.PHONE`
    width:100%;
    text-align: center;
  ` 
  }


  

  .viewLink{
    text-align: right;
    ${MEDIA.PHONE`
    text-align: center;
    `
  }

  p{
    color:#777777;
    ${MEDIA.PHONE`
      margin-bottom: 1rem;
    ` 
  }
`


export default ({data}) => (
  <Layout showTitle = "">
    <SEO title="Home" />
  
    <Container>
      <Content>


       <ShowListing>
        <Link to="/chromatic-terrain">
         
          <picture >
        <source media="(max-width: 350px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_350,q_auto,f_auto/v1602527722/chromatic-terrain/00_cover_chromatic-terrain_qtw8vi.jpg }`}/>
        <source media="(max-width: 600px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_600,q_auto,f_auto/v1602527722/chromatic-terrain/00_cover_chromatic-terrain_qtw8vi.jpg }`}/>
        <source media="(max-width: 900px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/00_cover_chromatic-terrain_qtw8vi.jpg }`}/>
        <source media="(max-width: 1200px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_1200,q_auto,f_auto/v1602527722/chromatic-terrain/00_cover_chromatic-terrain_qtw8vi.jpg }`}/>
        <img src={`https://res.cloudinary.com/ashow/image/upload/v1633327386/chromatic-terrain/00_cover_chromatic-terrain_qtw8vi.jpg`}  alt="Chromatic Terrain" />
     </picture>
     <br/>
          Chromatic Terrain. 17 paintings by Siméon Artamonov</Link>

       </ShowListing>

      </Content>
    </Container>
    
  </Layout>
)

export const query = graphql`
  query homeQuery {
    allStrapiShow {
      edges {
        node {
          id
          Title
          slug
          Description
          Poster {
            absolutePath
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          
        }
      }
    }
  }
`;
